<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";



import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'


import {
  required,
} from "vuelidate/lib/validators";

import { getPMApi } from '@/api/pm'
import { autoComplete } from '@/api/misc'
import { financial } from '@/api/misc'

/**
 * Setup PM  component
 */
export default {
  page: {
    title: "Deposit Release",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations() {
    if (this.release_type == 'Owner' || this.release_type == 'Tenant') {
      return {
        payee_name: { required },
        selected_amount: { required },
        supplier_address: {},
        supplier_name: {},
        invoice_number: {},
      }
    } else {
      return {
        payee_name: { required },
        selected_amount: { required },
        supplier_address: { required },
        supplier_name: { required },
        invoice_number: { required },
      }
    }

  },


  methods: {

    f(v, d = 2) {
      return financial(v, d)
    },

    formSubmit() {

      if (this.has_data_error) {
        this.$alertify.error(this.error_message)
        return
      }

      if (this.listingData.length <= 0) {
        this.error_message = 'No Release Entry'
        this.has_data_error = true
        this.$alertify.error(this.error_message)
        return

      }

      let data = {
        pm_id: this.property.property_id,
        release_payee_list: this.listingData
      }


      getPMApi().deposit_release(data).then(res => {
        if (res.errCode == 0) {
          this.$alertify.message("Deposit Release successfully");
          this.$router.push({ name: 'pm-property-list' })
        } else {
          this.$alertify.error("Deposit Release  failed:" + res.errCode);
        }
      })


    },





    onSearchSupplier(str) {

      autoComplete({ types: ['SEARCH_SUPPLIER'], str: str }).then(res => {
        if (res.errCode == 0) {
          this.searched_supplier_data = []
          res.data.map(e => {
            this.searched_supplier_data.push({
              str_id: e.name + '(' + e.supplier_code + ')',
              supplier: e
            })
          })
        }

      })

    },

    onSupplierSelected(evt) {
      this.supplier_name = evt.supplier.name
      this.supplier_address = evt.supplier.address
      this.supplier_code = evt.supplier.supplier_code

      this.payee_name = evt.supplier.name
      this.supplier_id = evt.supplier.id
    },


    check_amount() {
      this.has_data_error = false
      let amount = this.listingData.reduce((a, c) => a + c.amount, 0)

      if (amount > Number(this.property.deposit) + Number(this.property.owner_reserved)) {
        this.error_message = 'Released Amount Exceed Balance'
        this.has_data_error = true
        this.$alertify.error(this.error_message)
      }
    },

    addReleaseDetail() {
      this.$v.$touch()
      if (this.$v.$invalid == true) {
        return
      }

      let release_obj = {
        pay_type: this.release_type,
        amount: Number(this.selected_amount),
        name: this.payee_name,
        status : this.release_type
      }


      if (this.release_type == 'Supplier') {
        release_obj.address = this.supplier_address
        release_obj.invoice = this.invoice_number
        release_obj.supplier_id = this.supplier_id
      }

      this.listingData.push(release_obj)


      this.check_amount()
    },

    removeReleaseEntry(data) {
      this.listingData.splice(data.index, 1)
      this.check_amount()
    },


    onPayeeTypeChange() {
      if (this.release_type == 'Tenant') {
        this.payee_name = this.property.t_name
      } else if (this.release_type == 'Owner') {
        this.payee_name = this.property.owner_name

      }
    }


  },

  data() {
    return {
      title: "RTA Deposit Release",
      items: [
        {
          text: "PM List",
          href: "/pm/property_list",
        },
        {
          text: "RTA Deposit Release",
          active: true,
        },
      ],


      has_data_error: false,
      error_message: '',

      property: {},
      supplier_code: '',
      supplier_name: '',
      supplier_address: '',
      supplier_id: '',
      release_type: 'Owner',
      invoice_number: '',
      selected_amount: '',
      payee_name: '',

      searched_supplier_data: [],
      listingData: [],

      total_amount: 0
    };
  },
  components: {
    Layout,
    PageHeader,
    VueBootstrapTypeahead,

  },
  created() {
    getPMApi().detail({ pm_id: this.$route.query.pm_id }).then((res) => {
      if (res.errCode == 0) {
        this.property = res.data
      }

    })
  },
  mounted() {

  },

  watch: {

    supplier_name(new_supplier) {
      this.onSearchSupplier(new_supplier)
    },
  }
};
</script>



<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">

            <form class="needs-validation" @submit.prevent="formSubmit">

              <div class="row">
                <div class="col-md-3"><label class="form-label  font-size-16  bold">
                  Add Entry  
                </label>
                </div>
                <div class="col-md-9">
                  <div class="d-flex flex-wrap gap-2  justify-content-end ">
                    <label class="form-label  font-size-15 text-secondary ">RTA: {{ property.pm_id }} ,  Deposit: ${{  f(property.deposit).toLocaleString() }}</label>
                </div>
                </div>
              </div>
              <hr class="mt-1" >

              <div class="row  mt-3">
                <div class="col-lg-2">
                  <div class="mb-3 form-floating">

                    <select class="form-select" v-model="release_type" @change="onPayeeTypeChange" id="release_type">
                      <option value="Tenant">Tenant</option>
                      <option value="Supplier">Supplier</option>
                      <option value="Owner">Owner</option>
                    </select>
                    <label class="form-label" for="release_type">Release To</label>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="mb-3  form-floating">

                    <input class="form-control" v-model="payee_name" id="payee_name" placeholder="Payee"
                      :class="{ 'is-invalid': $v.payee_name.$error }" />
                   
                    <label class="form-label" for="payee_name">Payee</label>

                    <div v-if="$v.payee_name.$error" class="invalid-feedback">
                      <span v-if="!$v.payee_name.required">This value is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="mb-3  form-floating">

                    <input class="form-control" v-model="selected_amount" id="selected_amount" placeholder="Amount"
                      :class="{ 'is-invalid': $v.selected_amount.$error }" />

                    <label class="form-label" for="selected_amount">Amount</label>
                    <div v-if="$v.selected_amount.$error" class="invalid-feedback">
                      <span v-if="!$v.selected_amount.required">This value is required.</span>
                    </div>
                  </div>
                </div>


              </div>
              <!-- end row-->


              <div class="row mt-3 mb-3" v-if="release_type == 'Supplier'">
                <div class="col-lg-3">
                  <div class="mb-3">
                    <label class="form-label" for="gen-info-name-input">Supplier</label>
                    <vue-bootstrap-typeahead ref="supplier_name_ref" :disabled="release_type != 'Supplier'"
                      v-model="supplier_name" :value="supplier_name" :data="searched_supplier_data"
                      :inputClass="'is-invalid:' + `$v.supplier_name.$error`" :serializer="s => s.str_id"
                      :foramterDisplay="s => s.str_id" @hit="onSupplierSelected($event)" autocomplete="off" />
                  </div>
                </div>

                <div class="mb-3 col-lg-6">
                  <label class="form-label" for="gen-info-name-input">Supplier Address</label>
                  <input class="form-control" :disabled="release_type != 'Supplier'" v-model="supplier_address"
                    :class="{ 'is-invalid': $v.supplier_address.$error }" />
                  <div v-if="$v.supplier_address.$error" class="invalid-feedback">
                    <span v-if="!$v.supplier_address.required">This value is required.</span>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="mb-3">
                    <label class="form-label" for="gen-info-name-input">Invoice Number</label>
                    <input class="form-control" v-model="invoice_number" :disabled="release_type != 'Supplier'"
                      :class="{ 'is-invalid': $v.invoice_number.$error }" />
                    <div v-if="$v.invoice_number.$error" class="invalid-feedback">
                      <span v-if="!$v.invoice_number.required">This value is required.</span>
                    </div>
                  </div>
                </div>

              </div>
              <!-- end row-->

              <div class="row">

                <div class="col-lg-12 mb-3">
                  <div class="">
                    <b-button class="btn btn-soft-primary"  @click="addReleaseDetail">Add Entry</b-button>
                  </div>
                </div>
              </div>





              <div class="row mt-3">
                <div class="col-md-12"> <label class="form-label  font-size-16  bold">List</label>
                </div>
              </div>
              <hr class="mt-1" >
              
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex flex-wrap gap-2 justify-content-start d-flex">

                    <b-button type="submit" class="btn-primary" variant="primary" :disabled="listingData.length <= 0">
                      Submit
                    </b-button>
                    <b-button class="btn btn-soft-light"  @click="$router.push({ 'name': 'pm-property-list' })">
                      Cancel
                    </b-button>
                  </div>
                </div>
              </div>

              <div data-simplebar>
                <div class="table-responsive mt-3">
                  <div class="table align-middle table-nowrap">
                    <b-table :items="listingData" :fields="[{key :'ReleaseTo', tdClass: 'align-middle'}, { key :'Status', tdClass: 'align-middle'}, {key :'Amount', tdClass: 'align-middle'}, {key : 'Invoice', tdClass: 'align-middle'}, {key : 'Remove', tdClass: 'align-middle'}]" responsive="sm"
                      :per-page="listingData.length" class="table-check" thead-class="bg-light" :head-variant="'light'" 
                      hover show-empty>

                      <template #cell(ReleaseTo)="data">
                        {{ data.item.name }}
                      </template>

                      <template #cell(Status)="data">
                        {{ data.item.status }}
                      </template>                      
                      <template #cell(Amount)="data">
                        ${{ data.item.amount.toLocaleString() }}
                      </template>

                      <template #cell(Invoice)="data">
                        {{ data.item.invoice }}
                      </template>
                      <template #cell(Remove)="data">
                        <div class="row icon-demo-content-10">
                          <div class="col-xl-3 col-lg-4 col-sm-6" @click="removeReleaseEntry(data)"><i
                              class="uil-times font-size-4"></i></div>
                        </div>
                      </template>



                    </b-table>
                  </div>
                </div>
              </div>




            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
